import React, { useState } from "react";
import "./index.css";

import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
const InputField = ({
  value,
  placeholder,
  type,
  style,
  id,
  name,
  className,
  onChange,
  isRequired,
}) => {
  const [PasswordShown, setPasswordShown] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    onChange(value);
  };

  return (
    <div>
      <input
        type={PasswordShown ? "text" : type}
        value={value}
        className={className}
        style={style}
        id={id}
        placeholder={placeholder}
        name={name}
        required={isRequired}
        onChange={handleChange}
      />

      {type === "password" && (
        <div className="offset-md-10 offset-sm-10 offset-10 text-right eyeicon">
          {PasswordShown ? (
            <AiFillEyeInvisible
              onClick={() => setPasswordShown(!PasswordShown)}
            />
          ) : (
            <AiFillEye onClick={() => setPasswordShown(!PasswordShown)} />
          )}
        </div>
      )}
    </div>
  );
};

export default InputField;
