import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { RiAlipayFill } from "react-icons/ri";
function CustomCard(props) {
  return (
    <Card className="custom-card">
      <Card.Body className="p-3">
        <Row>
          <Col xxl={10} xl={11} lg={11} ms={11} sm={11}>
            <Card.Title className="card-title">{props.title}</Card.Title>
            <Card.Text className="card-description">
              {props.description}
            </Card.Text>
          </Col>
          <Col xxl={2} xl={1} lg={1} ms={1} sm={1}>
            <RiAlipayFill className="card-icon ms-2" size={30} />
          </Col>
          <div className="d-flex">
            <div></div>
          </div>
        </Row>
        <div className="mt-3 mb-0 px-0 card-footer-row">
          <div className="my-2">
            Version <b> {props.version}</b>
          </div>
          <div>
            Module Id <b>: {props.moduleId}</b>
          </div>
        </div>
        <Card.Text></Card.Text>
      </Card.Body>
    </Card>
  );
}

export default CustomCard;
