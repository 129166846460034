import React, { useState } from "react";
import TopNav from "./Widgets/TopNav";
import SideMenuBar from "./Widgets/SideMenuBar";

const RouteComponent = (props) => {
  const { component: Component, layout: Layout } = props;
  const [show, setShow] = useState(true);
  const getComponent = () => <Component />;

  return Layout !== undefined ? (
    <>
      <TopNav
        show={show}
        setShow={setShow}
        userName={"MHI"}
        userRole={"Admin"}
      />
      <div style={{ position: "relative" }}>
        <SideMenuBar show={show} setShow={setShow}>
          {getComponent()}
        </SideMenuBar>
      </div>
    </>
  ) : (
    <>{getComponent()}</>
  );
};

export default RouteComponent;
