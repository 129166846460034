import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { routesConfig } from './Utils/routes';
import RouteComponent from './RouteComponent';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
          {routesConfig.map((route) => (
            <Route
            key={route.path}
              path={route.path}
              element={
                <RouteComponent
                  layout={route.layout}
                  component={route.component}
                />
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;