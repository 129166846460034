/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CustomCard from "../CustomCard";
import axios from "axios";

const Connectors = () => {
  const [connectorsData, setConnectorsData] = useState([]);

  const constructConnectorsData = (data) => {
    if (Array.isArray(data)) {
      const tempConnectorData = data.map((connector) => ({
        title: connector.name,
        description: connector.description,
        version: connector.version,
        moduleId: connector.base.moduleId,
      }));
      setConnectorsData(tempConnectorData);
    }
  };

  const getAllConnectors = async () => {
    try {
      const resp = await axios.get("/api/v1/connector/getModules");
      constructConnectorsData(resp.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllConnectors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container className="container my-3 ms-auto">
      <div className="add-new-button ms-4 mt-1 mb-3">Add New Connector</div>
      <Row className="mx-2">
        {connectorsData.map((connector, index) => {
          return (
            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={4}
              className="p-3"
              key={index}
            >
              <CustomCard
                title={connector.title}
                description={connector.description}
                version={connector.version}
                moduleId={connector.moduleId}
              />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default Connectors;
