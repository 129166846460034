import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CustomCard from "../CustomCard";
import axios from "axios";

const Components = () => {
  const [componentsData, setComponentsData] = useState([]);

  const constructComponentsData = (data) => {
    if (Array.isArray(data)) {
      const tempComponentData = data.map((component) => ({
        title: component.name,
        description: component.description,
        version: component.version,
        moduleId: component.moduleId,
      }));
      setComponentsData(tempComponentData);
    }
  };

  const getAllComponents = async () => {
    try {
      const resp = await axios.get("/api/v1/component/getModules");
      constructComponentsData(resp.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllComponents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container className="container my-3 ms-auto">
      <div className="add-new-button ms-4 mt-1 mb-3">Add New Component</div>
      <Row className="mx-2">
        {componentsData.map((component, index) => {
          return (
            <Col xxl={4} xl={4} lg={4} md={4} sm={4} className="p-3">
              <CustomCard
                title={component.title}
                description={component.description}
                version={component.version}
                moduleId={component.moduleId}
              />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default Components;
