import React, { useEffect, useState } from "react";
import { Button, Container, ListGroup, Modal } from "react-bootstrap";
import MenuAndSubMenuNames from "../../Widgets/MenuAndSubMenuNames";
import BadgeWithIconAndText from "../../Widgets/CustomBadge";
import MaterialTableComponent from "../../Widgets/MaterialTable";
import { BsDot } from "react-icons/bs";
import { getBgColor, getTextColor } from "../../Utils/genericMethods";
import HealthStatusBadge from "../HealthStatusBadge";
import { BiFilter } from "react-icons/bi";
import axios from "axios";
import { IoMdSettings } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import Select from 'react-select';
import InputField from "../../Widgets/Inputfield";
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

const cellStyle = {
  whiteSpace: "nowrap",
  textAlign: "center",
  padding: 7,
};

const options = {
  toolbar: false,
  search: false,
  paging: false,
  title: false,
  filtering: false,
  headerStyle: {
    backgroundColor: "#E5E5E5",
    color: "black",
    fontSize: "14px",
    fontWeight: "600",
    zIndex: 0,
    textAlign: "center",
    padding: 7,
  },
  rowStyle: (row, index) => {
    if (index % 2 !== 0) {
      return {
        backgroundColor: "#F5F3F7",
        color: "#1A1C1E",
        fontSize: "14px",
      };
    } else {
      return {
        backgroundColor: "#F9F9F9",
        color: "#1A1C1E",
        fontSize: "14px",
      };
    }
  },
  actionsColumnIndex: -1,
};

const RealTimeMonitoring = (props) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [filterClause, setFilterClause] = useState("Live Status");
  const filterDopdownMenu = [
    "Live Status",
    "Connected",
    "Disconnected",
    "Provisioned",
    "Authentication Failed",
  ];
  const [convertedRespForTableData, setConvertedRespForTableData] = useState(
    []
  );
  const [tableData, setTableData] = useState([]);
  const [isEdgeNameClicked, setIsEdgeNameClicked] = useState(false);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [childTableData, setChildTableData] = useState([]);
  const [addNewModule, setAddNewModule] = useState(false);
  const [deleteModule, setDeleteModule] = useState(false);
  const [allModules, setAllModules] = useState([]);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [selectedModule, setSelectedModule] = useState({});
  const [moduleName, setModuleName] = useState("")
  const [openJSONEditor, setOpenJSONEditor] = useState(false)
  const [instance, setInstance] = useState([])
  const [selectedInstanceId, setSelectedInstanceId] = useState("");

  const selectStyle = {
    control: base => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: "none",
      backgroundColor: "#EEF7FF",

    }),
    dropdownIndicator: base => {
      return {
        ...base,
        dataName: 'indicator'
      };
    }
  };

  const handleSaveNewModule = async () => {
    const filteredModules = allModules.filter(
      (module) => module.moduleId === selectedModule.value
    );
    const bodyParams = {
      edgeId: selectedEdge.edgeId,
      ...filteredModules[0], name: moduleName
    }

    try {
      await axios.post('/api/v1/connector/addNewModule', bodyParams);
      setAddNewModule(false)
    } catch (err) {
      console.log(err);
    }
  }

  const getEdgeInstanceByEdgeId = async (edgeId) => {
    try {
      const resp = await axios.get(`/api/v1/connector/getInstances?edgeId=${edgeId}`);
      setChildTableData(resp.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getInstanceByInstanceId = async (instanceId) => {
    try {
      const resp = await axios.get(`/api/v1/connector/getInstances?instanceId=${instanceId}`);
      setInstance(resp.data)
      setOpenJSONEditor(true)

    } catch (err) {
      console.log(err);
    }
  };
  console.log(instance, "instance")
  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleEdgeNameClick = (e, rowData) => {
    if (selectedEdge !== null && rowData.edgeId === selectedEdge.edgeId) {
      setIsEdgeNameClicked(false);
      setSelectedEdge(null);
    } else {
      setSelectedEdge(rowData);
      getEdgeInstanceByEdgeId(rowData.edgeId)
      setIsEdgeNameClicked(true);
      const tempArray = [];
      tempArray.push(rowData);
      setChildTableData(tempArray);
    }
  };

  const columns = [
    {
      field: "name",
      title: "Edge Name",
      cellStyle,
      sorting: false,
      render: (rowData) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}
          onClick={(e) => handleEdgeNameClick(e, rowData)}
        >
          {rowData.name}
        </div>
      ),
    },
    { field: "edgeId", title: "Edge Id", cellStyle, sorting: false },
    {
      field: "status",
      title: "Status",
      render: (row) => {
        const bgColor = getBgColor(row.status);
        const textColor = getTextColor(row.status);
        return (
          <BadgeWithIconAndText
            icon={<BsDot />}
            text={row.status}
            bgColor={bgColor}
            textColor={textColor}
          />
        );
      },
      cellStyle,
      sorting: false,
    },
    {
      field: "healthStatus",
      title: "Health Status",
      cellStyle,
      sorting: false,

      render: (row) => {
        const bgColor = getBgColor(row.status);
        const textColor = getTextColor(row.status);
        return (
          <HealthStatusBadge
            bgColor={bgColor}
            textColor={textColor}
            cpuPerc={row.healthStatus[0]}
            ramPerc={row.healthStatus[1]}
            diskPerc={row.healthStatus[2]}
          />
        );
      },
    },
    { field: "lastUpdate", title: "Last Update", cellStyle, sorting: false },
  ];

  const childTableColumns = [
    { field: "instanceId", title: "Instance Id", cellStyle, sorting: false },
    {
      field: "name",
      title: "Name",
      cellStyle,
      sorting: false,
    },
    {
      field: "timestamp",
      title: "TimeStamp",
      cellStyle,
      sorting: false,
    },
    { field: "updatedAt", title: "Updated At", cellStyle, sorting: false },
  ];

  const actionsForChildTable = [
    {
      icon: () => <IoMdSettings size={15} />,
      tooltip: "Settings",
      onClick: (event, rowData) => {
        getInstanceByInstanceId(rowData.instanceId)
        setSelectedInstanceId(rowData.instanceId)
      }

    },
    {
      icon: () => <MdDelete size={15} />,
      tooltip: "Delete",
      onClick: (event, rowData) => {
        setDeleteModule(true)
      }
    },
  ];

  const handleFilterEdges = (val) => {
    if (val === "Live Status") {
      setFilterClause(val);
      setDropdownVisible(false);
      getAllEdgeInstance();
    } else if (val === "Authentication Failed") {
      setFilterClause(val);
      setDropdownVisible(false);
      const filteredData = convertedRespForTableData.filter(
        (item) => item.status === "Failed"
      );
      setTableData(filteredData);
    } else {
      setFilterClause(val);
      setDropdownVisible(false);
      const filteredData = convertedRespForTableData.filter(
        (item) => item.status === val
      );
      setTableData(filteredData);
    }
  };

  const generateRandomNames = (count) => {
    const names = new Set();
    const randomNames = [];
    const prefixes = ["CSPGCL-KOR", "CSPGCL-MAR"];
    let prefixIndex = 0;
    while (randomNames.length < count) {
      const randomSuffix = `M${String(
        Math.floor(Math.random() * 1000)
      ).padStart(3, "0")}`;
      const randomName = prefixes[prefixIndex] + "-" + randomSuffix;
      if (!names.has(randomName)) {
        names.add(randomName);
        randomNames.push(randomName);
      }
      prefixIndex = (prefixIndex + 1) % prefixes.length;
    }
    return randomNames;
  };

  const constructTableData = (resp) => {
    if (Array.isArray(resp.data)) {
      const randomNames = generateRandomNames(resp.data.length);
      const tempTableData = resp.data.map((edge, index) => ({
        name: randomNames[index],
        edgeId: edge.edgeId,
        status: edge.status,
        healthStatus: ["48%", "60%", "40%"],
        lastUpdate: "5 min ago",
      }));
      setTableData(tempTableData);
      setConvertedRespForTableData(tempTableData);
    }
  };

  const getAllEdgeInstance = async () => {
    try {
      const resp = await axios.get("/api/v1/edgeprovisioning/getEdgeInstances");
      constructTableData(resp);
    } catch (err) {
      console.log(err);
    }
  };

  const getAllModules = async () => {
    try {
      const resp = await axios.get("/api/v1/connector/getModules");
      let modules = resp.data.map(data => { return { label: data.name, value: data.moduleId } });
      setAllModules(resp.data)
      setModuleOptions(modules);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddNewModule = async () => {
    getAllModules();
    setAddNewModule(true)
  }

  const handleSaveAndPublish = async () => {
    try {
      await axios.post(`/api/v1/deploy/publish/${selectedInstanceId}/connector`);
      setOpenJSONEditor(false)
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllEdgeInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      className="container my-3 ms-auto"
      style={{ position: "relative", zIndex: -1, width: "100%" }}
    >
      <div className="d-flex">
        <div className="flex-grow-1 ">
          <MenuAndSubMenuNames
            menuName={"Real Time Monitoring"}
            subMenuName={filterClause}
          />
        </div>

        {!isEdgeNameClicked && (
          <div className="me-4 m-auto" style={{ position: "relative" }}>
            <div
              className="d-flex m-auto filter-button-div"
              onClick={toggleDropdown}
            >
              <div className="ms-2 my-1">
                <BiFilter size={28} className="mx-1" />
              </div>
              <div className="pt-2">Filter</div>
            </div>
            {isDropdownVisible && (
              <div
                className="dropdown-content"
                style={{ position: "absolute", top: "100%" }}
              >
                <ListGroup>
                  {filterDopdownMenu.map((option, index) => {
                    return (
                      <ListGroup.Item
                        key={index}
                        onClick={() => {
                          handleFilterEdges(option);
                        }}
                      >
                        {option}
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="d-flex">
        <div style={{ width: isEdgeNameClicked ? "35%" : "100%" }}>
          <MaterialTableComponent
            data={tableData}
            columns={columns}
            options={options}
          />
        </div>
        {isEdgeNameClicked && (
          <div style={{ width: "65%" }}>
            <div className="add-new-button-container">
              <div className="add-new-button ms-4 mt-1 mb-3" onClick={handleAddNewModule}>
                Add New Module
              </div>
            </div>
            <MaterialTableComponent
              data={childTableData}
              columns={childTableColumns}
              options={options}
              actions={actionsForChildTable}
            />
          </div>
        )}
      </div>

      {
        addNewModule && <Modal
          show={addNewModule}
          onHide={() => setAddNewModule(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add New Module
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col">
                <div className="py-1 form-field-label-custom">Modules</div>
                <Select
                  styles={selectStyle}
                  name="Modules"
                  options={moduleOptions}
                  value={selectedModule}
                  onChange={(selected) => setSelectedModule(selected)}
                />
              </div>
              <div className="col">
                <div className="headings">Module Name</div>
                <InputField
                  value={moduleName}
                  type="text"
                  id="modName"
                  name="modName"
                  className={`form-control text-box login-form-input-fields`}
                  onChange={setModuleName}
                />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setAddNewModule(false)}>Cancel</Button>
            <Button onClick={handleSaveNewModule}>Save</Button>
          </Modal.Footer>
        </Modal>
      }

      {deleteModule && <Modal
        show={deleteModule}
        onHide={() => setDeleteModule(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="contained-modal-title-vcenter">
              Add New Module
            </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you Sure? You want to delete?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteModule(false)}>Cancel</Button>
          <Button onClick={() => { }}>OK</Button>
        </Modal.Footer>
      </Modal>
      }

      {openJSONEditor &&
        <Modal
          show={openJSONEditor}
          onHide={() => setOpenJSONEditor(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <JSONInput
              id='a_unique_id'
              placeholder={instance[0]}
              locale={locale}
              height='550px'
              width="750px"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setOpenJSONEditor(false)}>Cancel</Button>
            <Button onClick={handleSaveAndPublish}>Save & Publish</Button>
          </Modal.Footer>
        </Modal>

      }
    </Container>
  );
};

export default RealTimeMonitoring;
