import React, { useEffect, useState } from "react";
function Application() {
    const [src,setSrc] = useState()
    useEffect(()=>{
        fetch('/application.json').then(data=>data.json()).then(data=>{
            console.log(data);
            setSrc(data.src);
        });
    });

    return src && <iframe src={src} frameborder="0" style={{ overflow: 'hidden', height: '90%', width: '90vw' }} height="90%" width="90vw"></iframe>
}
export default Application;