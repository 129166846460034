import RealTimeMonitoring from "../Components/RealTimeMonitoring";
import Login from "../Components/Login";
import Application from "../Components/Application";
import SideMenuBar from "../Widgets/SideMenuBar";
import Connectors from "../Components/Connectors";
import Components from "../Components/Components";

export const rootPath = '/'
export const realTimeMonitoringPath = '/realtime-monitoring'
export const applicationPath = '/application'
export const connectorsPath = '/connectors'
export const componentsPath = '/components'
export const routesConfig = [
  {
    path: rootPath,
    component: Login,
  },
  {
    path: realTimeMonitoringPath,
    component: RealTimeMonitoring,
    layout: SideMenuBar
  },
  {
    path: applicationPath,
    component: Application,
    layout: SideMenuBar
  },
  {
    path: connectorsPath,
    component: Connectors,
    layout: SideMenuBar
  },
  {
    path: componentsPath,
    component: Components,
    layout: SideMenuBar
  },
]
