export const getBgColor = (val)=>{
    let bgColor;
      switch (val) {
        case "Connected":
          bgColor = "#ECFDF3";
          break;
        case "Disconnected":
          bgColor = "#FFFEDD";
          break;
        case "Provisioned":
          bgColor = "#EEEEEE";
          break;
        case "Failed":
          bgColor = "#FFF2EA";
          break;
        default:
          bgColor = "#ECFDF3";
      }
      return bgColor
}

export const getTextColor =(val) =>{
      let textColor;
      switch (val) {
        case "Connected":
          textColor = "#027A48";
          break;
        case "Disconnected":
          textColor = "#F5C330";
          break;
        case "Provisioned":
          textColor = "#AAAAAA";
          break;
        case "Failed":
          textColor = "#F15046";
          break;
        default:
          textColor = "#027A48";
      }
      return textColor
}