import React from "react";
import MaterialTable from "material-table";

const MaterialTableComponent = (props) => {
  return (
    <div className="my-3 px-3">
      <MaterialTable
        columns={props.columns}
        data={props.data}
        enablePagination={false}
        actions={props.actions}
        options={props.options}
      />
    </div>
  );
};

export default MaterialTableComponent;
