import React from "react";
import BadgeWithIconAndText from "../../Widgets/CustomBadge";

const HealthStatusBadge = (props) => {
  return (
    <div className="d-flex align-items-center">
      <strong className="mx-2">CPU</strong>
      <BadgeWithIconAndText
        text={props.cpuPerc}
        bgColor={props.bgColor}
        textColor={props.textColor}
      />
      <strong className="mx-2">RAM</strong>
      <BadgeWithIconAndText
        text={props.ramPerc}
        bgColor={props.bgColor}
        textColor={props.textColor}
      />
      <strong className="mx-2">DISK</strong>
      <BadgeWithIconAndText
        text={props.diskPerc}
        bgColor={props.bgColor}
        textColor={props.textColor}
      />
    </div>
  );
};

export default HealthStatusBadge;
