import { useState, useEffect, useMemo } from "react";
import {
  MdApps,
  MdConnectedTv,
  MdMediation,
  MdOutlineSettingsInputComponent,
} from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import {
  rootPath,
  applicationPath,
  realTimeMonitoringPath,
  componentsPath,
  connectorsPath,
} from "../../Utils/routes";
import { IoMdExit } from "react-icons/io";
import { Container } from "react-bootstrap";

const SideMenuBar = (props) => {
  const navigate = useNavigate();

  const sideMenuBarData = useMemo(
    () => [
      {
        id: "rt-monitoring",
        groupName: "RT Monitoring",
        groupIcon: <MdConnectedTv size={20} />,
        onGroupClick: () => {
          navigate(realTimeMonitoringPath);
        },
      },
      {
        id: "connectors",
        groupName: "Connectors",
        groupIcon: <MdMediation size={20} />,
        onGroupClick: () => {
          navigate(connectorsPath);
        },
      },
      {
        id: "components",
        groupName: "Components",
        groupIcon: <MdOutlineSettingsInputComponent size={20} />,
        onGroupClick: () => {
          navigate(componentsPath);
        },
      },
      {
        id: "application",
        groupName: "Application",
        groupIcon: <MdApps size={20} />,
      },
    ],
    []
  );

  const [selectedMenu, setSelectedMenu] = useState("rt-monitoring");
  const [showSubMenu, setShowSubMenu] = useState(false);
  const location = useLocation();

  const handleClick = (id) => {
    if (id === "application") {
      navigate(applicationPath);
    }
    if (selectedMenu !== id) {
      setSelectedMenu(id);
      setShowSubMenu(true);
    } else {
      setShowSubMenu(!showSubMenu);
    }
  };

  useEffect(() => {
    const selectedRoute = location.pathname.split("/")[1];
    sideMenuBarData.forEach((sm) => {
      if (sm.id === selectedRoute) {
        setSelectedMenu(sm.id);
      }
    });
  }, [location, sideMenuBarData]);

  return (
    <Container
      className="d-flex m-0 p-0"
      style={{ position: "absolute", zIndex: 1 }}
    >
      <div className="sideMenuBar">
        {/* Sidebar/Menubar */}
        <div
          className={`shadow sideMenuBar__sidebar p-3 ${
            props.show ? "sideMenuBar__menubar shadow" : ""
          }`}
        >
          <div>
            {sideMenuBarData.map((menu, i) => {
              const { id, groupName, groupIcon, onGroupClick } = menu;

              return (
                <div key={i} className="menu-container">
                  <div
                    className={` ${
                      selectedMenu === id ? "menu-row-active mt-3" : ""
                    }`}
                    key={id}
                  >
                    <h6
                      className={
                        props.show
                          ? "fw-bolder pe-auto p-2"
                          : "sideMenuBar__nav-logo-name-hide p-2"
                      }
                      onClick={() => {
                        handleClick(id);
                      }}
                    >
                      {props.show ? (
                        <div
                          className={` ${
                            selectedMenu === id
                              ? "sideMenuBar__sidemenu-nav-active d-flex align-items-center"
                              : "sideMenuBar__nav-logo-name d-flex align-items-center"
                          }`}
                          onClick={onGroupClick && onGroupClick}
                        >
                          <div className="py-2 px-1">{groupIcon}</div>
                          <div className="py-2 px-1">{groupName}</div>
                        </div>
                      ) : (
                        <div
                          className={` ${
                            selectedMenu === id
                              ? "sideMenuBar__sidemenu-nav-active p-1 "
                              : "sideMenuBar__nav-logo-name p-1"
                          }`}
                        >
                          {groupIcon}
                        </div>
                      )}
                    </h6>
                  </div>
                </div>
              );
            })}
          </div>
          <hr />
          <div
            className="logout-div"
            onClick={() => {
              navigate(rootPath);
            }}
          >
            {props.show ? (
              <div className="d-flex align-items-center">
                <IoMdExit size={25} className="mx-2" />
                <span>Logout Account</span>
              </div>
            ) : (
              <IoMdExit size={25} className="mx-2" />
            )}
          </div>
        </div>
      </div>
      <Container style={{ position: "relative", zIndex: -1 }}>
        {props.children}
      </Container>
    </Container>
  );
};

export default SideMenuBar;
