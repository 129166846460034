import React from "react";

const MenuAndSubMenuNames = (props) => {
  return (
    <div style={{ zIndex: 1, position: "relative" }}>
      <h4 className="heading-text my-3 mx-4">
        {props.menuName} / <strong>{props.subMenuName}</strong>
      </h4>
    </div>
  );
};

export default MenuAndSubMenuNames;
