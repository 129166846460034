import React from "react";

const BadgeWithIconAndText = (props) => {
  const { icon, text, bgColor, textColor } = props;
  const badgeStyle = {
    backgroundColor: bgColor,
    color: textColor,
  };
  return (
    <div className="custom-badge" style={badgeStyle}>
      {icon ? (
        <>
          <span className="badge-icon">{icon}</span>
          <span className="badge-text me-2">{text}</span>
        </>
      ) : (
        <>
          <span className="only-badge">{text}</span>
        </>
      )}
    </div>
  );
};

export default BadgeWithIconAndText;
