import React from "react";
import PropTypes from "prop-types";
const Button = ({ value, icon, onClick, style, type, className, iconEnd }) => (
  <button
    className={className}
    style={style}
    type={type}
    onClick={(event) => onClick?.(event)}
  >
    {icon}
    {value}
    {iconEnd}
  </button>
);

Button.propTypes = {
  styleClass: PropTypes.string,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
  styleClass: "btn-primary",
};

export default Button;
