import React from "react";
import "./index.css";
export default function Card(props) {
  return (
    <div
      className={`card ${props.className}`}
      onClick={() => props.onCardClick && props.onCardClick()}
      style={props.style}
    >
      {props.children}
    </div>
  );
}
